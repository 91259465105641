@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Tahu";
  src: url("/src/assets/fonts/Tahu.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
} */

@layer utilities{
  .text-shadow{
    text-shadow: 2px 2px #fff;
  }
  .tahu-font{
    font-family:"Tahu";
  }
  .dashboard-side-bar{
   
    width:80px;
    min-height:100vh;
    min-width:80px;
    transition:  min-width .5s;
    overflow:hidden;
    
  }
  .dashboard-side-bar:hover{
    
    min-width:200px;
    color:black;
    
  }
  .hover-item{
    color:white !important;
   
  }
  .hover-item:hover{
    background:white;
    color:#000 !important ;
  }
  .profile-card-position{
    display:flex !important;
    flex-direction:column !important;
    align-items:center !important;
    justify-content:center !important;
  }

  @keyframes snackbar-enter {
    from {left:-500px;}
    to {left:0px;}
  }

 

  .snackbar{
    position:absolute !important; 
    bottom:0px;
    left:0px;
    animation-name:snackbar-enter;
    animation-duration:.5s;
    margin:16px;
  }

  .rotate{
    transform:rotate(0deg);
    transition:transform .5s;
  }

  .rotate:hover{
    transform:rotate(108deg);
    transform:.5s;
  }
  

  .draw-self{
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 10s linear forwards;
   
   
  }
  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }

 .blobs-bckg{
   background-image:url("./assets/svg/blobs2.svg");
   background-size:contain;
   background-repeat:no-repeat;
   background-position:center;
 }

 .glass{
  /* bg-opacity-50 backdrop-filter backdrop-blur-md */
 }

  
}

::-webkit-scrollbar {
  width: 5px !important;
 
}

::-webkit-scrollbar-track {
  
  
  background:#444;
}

::-webkit-scrollbar-thumb {
  
  background-color: lightgray;
 
  
}
